<template>
  <div class="selected-options-product selected-options-product__size">
    <span class="selected-options-product__size_label" v-show="showLabel">{{$t('orderSummary.size')}}</span>
    <span class="selected-options-product__size_value">{{option.value}}</span>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    }
  }
}
</script>
