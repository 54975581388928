import { render, staticRenderFns } from "./MiniCartProduct.vue?vue&type=template&id=61f5e6c8&"
import script from "./MiniCartProduct.vue?vue&type=script&lang=js&"
export * from "./MiniCartProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MiniCartProduct.vue"
export default component.exports