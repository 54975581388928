<template>
  <div class="selected-options">
    <div v-for="(variant, index) in variantsSorted" :key="index" class="selected-option">
      <component
      v-if="availableComponent[variant.attribute_code] && variant.value"
      :is="availableComponent[variant.attribute_code]"
      :option="variant" />
    </div>
  </div>
</template>

<script>

// load component custom
import ColorSelected from './SelectedOptions/ColorSelected'
import SizeSelected from './SelectedOptions/SizeSelected'
import GiftCardSelected from './SelectedOptions/GiftCardSelected'
import sortBy from 'lodash/sortBy'

const varinatsMap = {
  'color_detail': 0,
  'size': 1
}

export default {
  props: {
    variants: {
      type: Array,
      required: true
    }
  },
  computed: {
    availableComponent () {
      return {
        'color_detail': ColorSelected,
        'size': SizeSelected,
        'giftCard': GiftCardSelected
      }
    },
    variantsSorted () {
      return sortBy(this.variants, function (variant) {
        return varinatsMap[variant.attribute_code] || 0
      })
    }
  },
  components: {
    ColorSelected,
    SizeSelected,
    GiftCardSelected
  }
}
</script>
