<template>
  <div class="selected-options-product selected-options-product__gift-options">
    <ul v-for="(option, index) in optionsList" :key="index" class="gift-options-list nav flex-column">
      <li class="gift-options-list_item">
        <div class="gift-options-list_item_label" v-t="option.label"></div>
        <div class="gift-options-list_item_value">{{ option.value }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  computed: {
    optionsList () {
      let whitelist = [
        'aw_gc_recipient_email',
        'aw_gc_sender_email',
        'aw_gc_message'
      ]
      let list = {}
      let arrConsent = []
      if (Object.keys(this.option).length && Object.keys(this.option.value).length) {
        list = this.option.value
      }
      whitelist.forEach(element => {
        if (list[element]) {
          arrConsent.push({ label: element, value: list[element] })
        }
      })
      return arrConsent
    }
  }
}
</script>
