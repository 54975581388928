<template>
  <div class="selected-options-product selected-options-product__color">
    <span class="color-label">{{ $t('color') }}</span>
    <div class="color-box">
      <span class="color-box_label text-lowercase">{{option.value}}</span>
      <span class="color-box_badge" :style="styleComp"></span>
    </div>
  </div>
</template>

<script>
import Config from 'config'

export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleComp () {
      return this.option.color_image && this.option.color_image.includes('#')
        ? `background: #${this.option.color_image.split('#')[1]}`
        : `center / contain url(${Config.API.swatchPath + this.option.color_image})`
    }
  }
}
</script>
